window.GeneralService = new (function () {

    const Swal = require('sweetalert2');

    var that = this;

    /**
     * Init
     */
    this.init = function () {

        $('.action-show-service-lightbox').on('click', that.showLightBoxServiceAction);

    };

    this.showLightBoxServiceAction = function() { 
        let windowWidth = $(window).width();
        var imgtoShow = imageURL;

        if(windowWidth <= 700) {
            imgtoShow = imageURLxs;
        }

        if(showServiceLightBox) {
            Swal.fire({
                showConfirmButton: false,
                customClass: 'service-popup-lightbox',
                showCloseButton: true,
                html: `
                    <img src="`+imgtoShow+`" class="img-fluid" style="" />
                `,
                onClose: () => {
                    //$('.service-person-container').attr('style', 'bottom: -500px;');
                }
            });
        }

        //Ajax parameters
        var settings = {
            url: cookieServiceRoute,
            method: "GET",
        }

        //ajax call
        $.ajax(settings).done(function (response) {
            console.log(response);
        }).fail(function (xhr, status, error) {
            console.log('error in create cookie');
        });
    };


})();
