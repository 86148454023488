export function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();
    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

const $bodyAndHTML = $('body, html');
export const scrollTo = ($element, offset = 50) => {
    const _offset = $element.offset().top;
    $bodyAndHTML.animate({
        scrollTop: _offset - offset
    }, 500);
} 

export  function getCallbacksSummernote (max, $element) {
    const $maxCounter =  $element.nextAll('.maxContentPost:first').html(max);
    const callbackMax = (freeCharters) => {
        $maxCounter.html(freeCharters);
    }
    return {
        onKeydown: function(e) {
            var t = e.currentTarget.innerText;
            if (t.trim().length >= max) {
                const allowKeys = [8, 37, 38, 39, 40];
                allowKeys.indexOf(e.keyCode) ==-1 && e.preventDefault();
            }
        },
        onKeyup: function(e) {
            var t = e.currentTarget.innerText;
            if (typeof callbackMax == 'function') {
                callbackMax(max - t.trim().length);
            }
        },
    };
}

export function showBadgeErrors(errorsResponse, $rootElement) {
    /**
     * Los JSON de respuesta de Validation de Laravel sguiente la siguiete estructura
     * {
     *  mensaje: 'The given data was invalid.',
     *  errors:{
     *      field1 :[ 
     *          "Error 1", ... , "Error n",
     *      ], ..., 
     *      fieldn :[ 
     *          "Error 1", ... , "Error n",
     *      ]
     *   }
     * }
     * 
     * Aprovechando esto, los badge de error debarán tene una clase .error-{field} 
     * 
     */
    if(errorsResponse.errors) {
        for(let field in errorsResponse.errors) {
            let $badgeContainer = $rootElement.find(`.error-${field}`)
            $badgeContainer.find('.badge')
                .html(errorsResponse.errors[field][0])
                .end()
            .removeClass('badge-hide');
        };
        const $badge = $rootElement.find('.error:not(.badge-hide):first');
        if($badge.length) {
            if(!isScrolledIntoView($badge[0])) {
                scrollTo($badge.data('scrollTo') || $badge.parent());
            }
        }
     }
}

export function hideError($rootElement) {
    $rootElement.find('.error').addClass('badge-hide');
}