window.PreOwned = new(function () {

    var that = this;

    /**
     * Init
     */
    this.init = function () {

        $('.openFilter').on('click', that.showFilters);
        $('.addFavorite').on('click', that.addFavorite);

        //carousel
        $('.CarDetail-sldr').slick({
            arrows: false,
            dots: false,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: 'ondemand',
            lazyLoaded: function (event, image) {
                console.log(image);
            },
            asNavFor: '.CarDetail-thumb'
        });
        $('.CarDetail-thumb').slick({
            dots: false,
            arrows: false,
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            focusOnSelect: true,
            asNavFor: '.CarDetail-sldr',
            responsive: [{
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 3,
                    }
                },
            ]

        });
        //carousel
        $('.CarDetail-related-Sldr').slick({
            arrows: false,
            dots: false,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            lazyLoad: 'ondemand',
            lazyLoaded: function (event, image) {
                console.log(image);
            },
            responsive: [{
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    }
                },
            ]
        });

        // Remove active class from all thumbnail slides
        $('.CarDetail-thumb .slick-slide').removeClass('slick-active');

        // Set active class to first thumbnail slides
        $('.CarDetail-thumb .slick-slide').eq(0).addClass('slick-active');

        // On before slide change match active thumbnail to current slide
        $('.CarDetail-sldr').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            var mySlideNumber = nextSlide;
            $('.CarDetail-thumb .slick-slide').removeClass('slick-active');
            $('.CarDetail-thumb .slick-slide').eq(mySlideNumber).addClass('slick-active');
        });

        $('.gotoform').on('click', function () {
            $('html, body').animate({
                scrollTop: $("#contact-preowned-form").offset().top - 100
            }, 2000);
        });
    };

    /**
     * Banners
     */
    this.showFilters = function () {
        $('.FilterBox').slideToggle();
        return false;
    };

    /**
     * Favorites
     */
    this.addFavorite = function () {
        $(this).toggleClass('added');

        var preownedID = $(this).data('preowned');

        $.ajax({
            type: 'POST',
            url: savePreownedFavoriteRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'preowned_id': preownedID,
            },
            success: function (response) {

                //car added
            }
        });

    };


})();
