window.EstimatePayment = new(function () {

    var that = this;
    //var coinSymbol = '$';
    percent = 0;

    /**
     * Listener Estimate payments
     */
    this.init = function () {
        percent = (typeof $('#branch-financing').val() != 'undefined') ? $('#branch-financing').val() : percent;
        $('#estimate-car-model').on('change', that.getTrimsEstimatePayment);
        $('#trim').on('change', that.changeTrimPrice);
        $('#terms-months').on('change', that.changeTermsMonthly);

        $('#down-payment').keyup(delay(function (e) {
            that.addDownPayment($(this).val());
        }, 500));

        $('#branch-financing').on('change', that.changePercent);
    };

    /**
     * Get the percent for the dealer financing
     */
    this.changePercent = function() {
        var currentValue = $(this).val();
        if ( currentValue && !isNaN(currentValue) ) {
            percent = $(this).val();
        }

        if(currentCountry == 'costa-rica') {
            //For Costa Rica
            that.costaRica();
        }

        that.updateElements();
    };

    /**
     * get trims for model Estimate payments
     */
    this.getTrimsEstimatePayment = function () {
        var selectedModel = $(this).children('option:selected').val();

        //ajax get trims
        $.ajax({
            type: 'POST',
            url: getTrimsRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'car_id': selectedModel,
            },
            success: function (response) {

                var selectOptions = '';

                $.each(response, function (index, value) {
                    if(index == 0) {
                        trimPrice = value.price;
                    }

                    var priceToShow = '';
                    var coinSymbolToShow = (value.price_text !== null) ? value.price_text : value.coin_symbol;

                    if(coinSymbolToShow == null) {
                        coinSymbolToShow = ''; 
                    }
                    if(parseFloat(value.price) > 0) {
                        priceToShow = value.price;
                    }

                    selectOptions += '<option data-price="'+ value.price +'" data-pricetext="'+ value.price_text +'" value="'+ value.name +'">';
                    selectOptions += value.name;
                    selectOptions += (priceToShow != '') ? ' - ' + coinSymbolToShow + ' ' + BuildCars.formatNumber(priceToShow) : '';
                    selectOptions += '</option>';

                    coinSymbol = coinSymbolToShow;
                });

                $('#trim option').remove();
                $('#trim').append(selectOptions);
                $('#trim').trigger('change');

                that.updateElements();

            }
        });

    };

    /**
     * Change trim value when
     * user select a different
     */
    this.changeTrimPrice = function () {
        var newTrimPrice = $(this).find('option:selected').data('price');
        var newTrimPriceAWD = $(this).find('option:selected').data('price-awd');
        selectedCar['price_text'] = $(this).find('option:selected').data('pricetext');

        if(selectedCar['powertrain'] == 'AWD') {
            newTrimPrice = newTrimPriceAWD;
        }
        trimPrice = parseFloat(newTrimPrice);

        //20% downpayment
        downPayment = (20 / 100) * trimPrice;

        //$('#down-payment').attr('value', downPayment);
        $('#down-payment').val(downPayment.toFixed(2));
        $('#down-payment').trigger('keyup');

        //check for available powertrain
        BuildCars.getPowertrain();

        that.updateElements();
    };

    /**
     * Change terms monthly when
     * user select a different
     */
    this.changeTermsMonthly = function () {

        if(currentCountry == 'costa-rica') {
            //For Costa Rica
            that.costaRica();
        }

        that.updateElements();
    };

    /**
     * Add down payment
     * if user added
     *
     * @param quantity
     */
    this.addDownPayment = function (quantity) {
        quantity = (quantity == '') ? 0 : BuildCars.notFormatNumber(quantity);
        downPayment = parseFloat(quantity);

        //var formatedDownPayment = BuildCars.formatNumber(downPayment.toFixed(2));
        //$('#down-payment').val(formatedDownPayment);

        if(currentCountry == 'costa-rica') {
            //For Costa Rica
            that.costaRica();
        }

        that.updateElements();
    };

    /**
     * Update Elements with prices
     */
    this.updateElements = function () {
        //terms months
        termMonths = $('#terms-months').find('option:selected').val();
        //rate
        var rate = (percent * trimPrice) / 100;

        if(trimPrice > 0) {
            newTrimPrice = trimPrice - downPayment.toFixed(2);
        } else {
            newTrimPrice = trimPrice - downPayment.toFixed(2);
        }

        if(currentCountry == 'guatemala') {
            //For Guatemala
            totalPriceMonthly = that.guatemala(newTrimPrice, rate);

        } else {
            //getting the total
            totalPriceMonthly = (newTrimPrice + rate) / termMonths;
        }

        if(totalPriceMonthly <= 0) {
            totalPriceMonthly = 0;
        }

        var priceText = '';
        if(typeof selectedCar['price_text'] != 'undefined' && selectedCar['price_text'] !== null && !$.isNumeric(selectedCar['price_text'])) {
            //priceText = '<small>' + selectedCar['price_text'].toUpperCase() + '</small>';
            priceText = selectedCar['price_text'].toUpperCase();
        } else {
            priceText = coinSymbol;
        }

        //Monthly Payment
        $('.EstimateAmount').html(priceText + BuildCars.formatNumber(totalPriceMonthly.toFixed(2)));

        BuildCars.pushNewSummaryData();

    };

    /**
     * Delay for keyup
     * @param callback
     * @param ms
     * @returns {function(...[*]=)}
     */
    function delay(callback, ms) {
        var timer = 0;
        return function() {
            var context = this, args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                callback.apply(context, args);
            }, ms || 0);
        };
    }

    /**
     *  Start Costa Rica
     *  ==============================================================================================================
     */


    /**
     * Listener
     */
    this.costaRica = function () {
        var monthsCR = $('#terms-months').val();
        var branchIdCR = $('#branch-financing').children("option:selected").data('branch');

        that.searchAdjustmentCostaRica(monthsCR, branchIdCR);
    };

    /**
     * Search Bank Adjustment
     * @param monthsCR
     * @param branchIdCR
     */
    this.searchAdjustmentCostaRica = function (monthsCR, branchIdCR) {
        $('.wait-for-change').addClass('active');
        $.ajax({
            type: 'POST',
            url: searchAjustmentRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'branch_id': branchIdCR,
                'months': monthsCR,
            },
            success: function (response) {

                that.doAdjustmentCostaRica( response[1] );

            }
        });
    };

    /**
     * Apply the adjustment
     * @param quantity
     */
    this.doAdjustmentCostaRica = function (quantity) {
        var downPayment = $('#down-payment').val();
        var montoFinanciar = trimPrice - downPayment;
        totalPriceMonthly = (montoFinanciar / 10000) * quantity;

        //Monthly Payment
        $('.EstimateAmount').html(coinSymbol + BuildCars.formatNumber(totalPriceMonthly.toFixed(2)));
        $('.wait-for-change').removeClass('active');

        BuildCars.pushNewSummaryData();
    };

    /**
     *  End Costa Rica
     *  ==============================================================================================================
     */

    /**
     *  Start Guatemala
     *  ==============================================================================================================
     */


    /**
     * Listener
     */
    this.guatemala = function (newTrimPrice, rate) {

        var priceAdjustment = that.doAdjustmentGuatemala();

        newTrimPrice = newTrimPrice + priceAdjustment;
        var interest = percent / 100;
        var interestRate = interest / 12;
        rate = interestRate * newTrimPrice;
        var interestRateFixed = parseFloat(interestRate.toFixed(8));
        var pow = 1-(1 + interestRateFixed) ** (termMonths * -1);
        var rateFixed = parseFloat(rate.toFixed(4));
        var powFixed = parseFloat(pow.toFixed(8));

        var monthlyPayment = rateFixed / powFixed;
        return monthlyPayment;
    };

    this.doAdjustmentGuatemala = function() {

        var montoFinanciar = trimPrice - downPayment;
        var percentEscrituracion = (2.3 / 100) * montoFinanciar;

        return percentEscrituracion;
    };


    /**
     *  End Guatemala
     *  ==============================================================================================================
     */

})();
