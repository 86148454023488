window.OnlineAppointment = new (function () {

    const Swal = require('sweetalert2');

    var that = this;

    /**
     * Init
     */
    this.init = function () {

        //that.slickFormSteps();
        that.slickOffers();
        $('.btn-actions').on('click', that.validateStep);

        //
        $('#not-sure-service').on('click', that.enableDisableStep1Field);

        //current date
        that.currentDateForInput();

        //back in form
        $('.btn-actions.prev').on('click', that.goBackForm);

        //Common Services
        $('.common-services-wrap ul li em').on('click', that.showMoreInfoService);

        //input time configuration by location
        $('#location').on('change', that.inputTimeConfigure);
        $('#time').focusout(that.checkInputTime);

    };

    /**
     * Validate current step
     */
    this.validateStep = function () {
        var currentStep = $(this).data('current_step');


        if($(this).hasClass('next')) {

            $(this).html(nextTextButton);

            if (currentStep == 1) {
                that.validateStep1();
            } else if (currentStep == 2) {
                that.validateStep2();
            } else if (currentStep == 3) {
                $(this).html(endTextButton);
                that.validateStep3();
            } else if (currentStep == 4) {
                $(this).html(sendingTextButton);
                that.validateStep4();
            }

        }
    };

    /**
     * Enable / Disable Step 1 fields
     */
    this.enableDisableStep1Field = function () {

        if ($('#not-sure-service:checked').length > 0) {
            $('.wrap-services input').attr('disabled', true);
            $('.wrap-services input').parent().addClass('disabled');
        } else {
            $('.wrap-services input').removeAttr("disabled");
            $('.wrap-services input').parent().removeClass("disabled");
        }

    };

    /**
     * validate step 1
     */
    this.validateStep1 = function () {
        var notSureService = ($('#not-sure-service').is(':checked')) ? 'not-sure-service' : '';

        var inputs = $('.wrap-services input');
        var servicesSelected = [];

        $.each(inputs, function (index, input) {

            if ($(input).is(':checked')) {
                servicesSelected[index] = $(input).val();
            }

        });

        if (servicesSelected.length > 0 || notSureService != '') {
            //pass validation
            that.showStep(2);
            return;
        }

        Swal.fire('', step1FailTitle, 'warning');
    };

    /**
     * validate step 2
     */
    this.validateStep2 = function () {
        var location = $('#location').children("option:selected"). val();
        var locationRequired = $('#location').attr('required');
        var locationValidated = true;
        var date = $('#date').val();
        var dateRequired = $('#date').attr('required');
        var dateValidated = true;
        var time = $('#time').val();
        var timeRequired = $('#time').attr('required');
        var timeValidated = true;


        //validate location
        $('#location').removeClass('error');
        if(('#location').length > 0 && typeof locationRequired != 'undefined' && location == '') {
            locationValidated = false;
            $('#location').addClass('error');
        }

        //validate date
        $('#date').removeClass('error');
        if(('#date').length > 0 && typeof dateRequired != 'undefined' && date == '') {
            dateValidated = false;
            $('#date').addClass('error');
        }

        //validate time
        $('#time').removeClass('error');
        if(('#time').length > 0 && typeof timeRequired != 'undefined' && time == '') {
            timeValidated = false;
            $('#time').addClass('error');
        }

        if ( locationValidated && dateValidated && timeValidated) {
            //pass validation
            that.showStep(3);
            return;
        }

        Swal.fire('', step2FailTitle, 'warning');
    };

    /**
     * validate step 3
     */
    this.validateStep3 = function () {
        var vin = $('#vin').val();
        var vinRequired = $('#vin').attr('required');
        var vinValidated = true;
        var currentKm = $('#current-km').val();
        var currentKmRequired = $('#current-km').attr('required');
        var currentKmValidated = true;
        var licensePlate = $('#license-plate').val();
        var licensePlateRequired = $('#license-plate').attr('required');
        var licensePlateValidated = true;

        //validate vin
        $('#vin').removeClass('error');
        if(('#vin').length > 0 && typeof vinRequired != 'undefined' && $.trim(vin) == '') {
            vinValidated = false;
            $('#vin').addClass('error');
        }

        //validate current km
        $('#current-km').removeClass('error');
        if(('#current-km').length > 0 && typeof currentKmRequired != 'undefined' && $.trim(currentKm) == '') {
            currentKmValidated = false;
            $('#current-km').addClass('error');
        }

        //validate license plate
        $('#license-plate').removeClass('error');
        if(('#license-plate').length > 0 && typeof licensePlateRequired != 'undefined' && $.trim(licensePlate) == '') {
            licensePlateValidated = false;
            $('#license-plate').addClass('error');
        }

        if ( vinValidated && currentKmValidated && licensePlateValidated) {
            //pass validation
            that.showStep(4);
            return;
        }

        Swal.fire('', step3FailTitle, 'warning');
    };

    /**
     * validate step 4
     */
    this.validateStep4 = function () {
        var name = $('#owner-name').val();
        var nameRequired = $('#owner-name').attr('required');
        var nameValidated = true;
        var email = $('#owner-email').val();
        var emailRequired = $('#owner-email').attr('required');
        var emailValidated = true;
        var phone = $('#owner-phone').val();
        var phoneRequired = $('#owner-phone').attr('required');
        var phoneValidated = true;

        //validate name
        $('#owner-name').removeClass('error');
        if(('#owner-name').length > 0 && typeof nameRequired != 'undefined' && $.trim(name) == '') {
            nameValidated = false;
            $('#owner-name').addClass('error');
        }

        //validate email
        $('#owner-email').removeClass('error');
        if(('#owner-email').length > 0 && typeof emailRequired != 'undefined' && $.trim(email) == '') {
            emailValidated = false;
            $('#owner-email').addClass('error');
        }

        //validate phone
        $('#owner-phone').removeClass('error');
        if(('#owner-phone').length > 0 && typeof phoneRequired != 'undefined' && $.trim(phone) == '') {
            phoneValidated = false;
            $('#owner-phone').addClass('error');
        }

        if ( nameValidated && emailValidated && phoneValidated ) {
            //pass validation
            that.saveAppointmentInfo();
            return;
        }

        Swal.fire('', step4FailTitle, 'warning');
    };

    /**
     * Show Step
     * @param stepNumber
     */
    this.showStep = function (stepNumber) {
        var prevStep = stepNumber - 1;

        $('.steps').addClass('absolute');

        $('.step-' + prevStep).attr('style', 'margin-left: -100%');
        $('.step-' + stepNumber).addClass('active');

        setTimeout(function () {
            $('.step-' + stepNumber).attr('style', 'margin-left: 0');
            $('.step-' + prevStep).attr('style', 'margin-left: -100%').removeClass('active');
        }, 500);

        if (stepNumber > 1) {
            $('.btn-actions.prev').addClass('active');
        } else {
            $('.btn-actions.prev').removeClass('active');
        }

        var stepHeight = $('.step-' + stepNumber).height();
        that.adjustHeight(stepHeight);

        $('.steps').removeClass('absolute');

        //go to top
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".Online-appointment").offset().top
        }, 800);

        that.updateGeneralStep(stepNumber);
    };

    /**
     * Go back in form
     */
    this.goBackForm = function () {
        var currentStep = $(this).data('current_step');
        var prevStep = currentStep - 1;

        $('.btn-actions.next').html(nextTextButton);

        $('.step-' + currentStep).attr('style', 'margin-left: 100%');

        setTimeout(function () {
            $('.step-' + currentStep).removeClass('active');
            $('.step-' + prevStep).attr('style', 'margin-left: 0').addClass('active');
        }, 500);

        if(prevStep == 1) {
            $('.btn-actions.prev').removeClass('active');
        }

        var stepHeight = $('.step-' + prevStep).height();
        that.adjustHeight(stepHeight);

        that.updateGeneralStep(prevStep);
    };

    /**
     * General data steps
     */
    that.updateGeneralStep = function (stepNumber) {

        var currentStepTitle = step1FormTitle;
        if (stepNumber == 2) {
            currentStepTitle = step2FormTitle;
        } else if (stepNumber == 3) {
            currentStepTitle = step3FormTitle;
        } else if (stepNumber == 4) {
            currentStepTitle = step4FormTitle;
        }

        $('.form-position-title .number').html(stepNumber);
        $('.form-position-title .title').html(currentStepTitle);

        $('.btn-actions').data('current_step', stepNumber);
    };

    /**
     * Offer Banners
     */
    this.slickOffers = function () {

        $('.main-wrap-offers').slick({
            dots: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            autoplay: true,
            autoplaySpeed: 5000,
            appendDots: $(".navDots")
        });

    };

    /**
     * Current date for input
     */
    this.currentDateForInput = function () {
        var date = new Date();
        var day = date.getDay();
        var month = date.getMonth();
        var year = date.getFullYear();

        if(siteLang == 'es') {
            $('#date').val(day+'/'+month+'/'+year);
        } else {
            $('#date').val(month+'/'+day+'/'+year);
        }

    };

    /**
     * Adjust Height Steps
     * @param newHeight
     */
    this.adjustHeight = function (newHeight){

        var finalHeight = newHeight + 40;
        $('.wrap-steps').attr('style', 'min-height: ' + finalHeight + 'px');

    };

    /**
     * Save appointment info
     */
    this.saveAppointmentInfo = function () {
        var form = $('#form-online-appointment').serialize();

        //show wait
        Swal.fire({
            title: sendingInfoTitle,
            text: sendingInfoDescription,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        //Ajax parameters
        var settings = {
            url: sendOnlineAppointmentRoute,
            method: "POST",
            data: form
        }

        //ajax call
        $.ajax(settings).done(function (response) {

            if(response.response != 'error') {
                Swal.fire(
                    dataSendTitle,
                    dataSendDescription,
                    'success'
                );
            } else {
                Swal.fire(
                    errorTitle,
                    errorDescription,
                    'error'
                );
            }
        }).fail(function (xhr, status, error) {
            console.log('error trying to send data');
        });

        //Googel Event
        //ga('send','event', 'Appointment', 'appointment-send', 'click submit appointment');
        try {
            gtag('event', 'Appointment', {
                'event_category': 'appointment-send',
                'event_label': 'click submit appointment'
            });
        } catch (e) {
            console.log(e);
            console.log('Error send event GA4');
        }

    }

    /**
     * Accordion Know your Honda
     */
    this.showMoreInfoService = function () {
        var isActive = $(this).parent().hasClass('active');
        var liHeight = $(this).parent().height();
        var descriptionHeight = $(this).next().next().height();
        var totalHeight = liHeight+descriptionHeight;

        if(isActive) {
            $(this).parent().removeClass('active');
            //$(this).parent().attr('style', 'max-height: ' + liHeight + 'px; height:' + liHeight + 'px;');
        } else {
            $(this).parent().addClass('active');
            //$(this).parent().attr('style', 'max-height: ' + totalHeight + 'px; height:' + totalHeight + 'px;');
        }
    };

    /**
     * Input time configure by dealer location
     */
    this.inputTimeConfigure = function () {

        var minTime = $('#location option:selected').data('mintime');
        var maxTime = $('#location option:selected').data('maxtime');


        $('#time').timepicker({
            timeFormat: 'H:i',
            interval: 60,
            minTime: minTime,
            maxTime: maxTime,
            defaultTime: minTime,
            startTime: minTime,
            dynamic: true,
            dropdown: true,
            scrollbar: true
        });


    };


})();
