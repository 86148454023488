window.WorkShop = new(function () {
    var that = this;

    /*
     * Init
     */

    this.init = function () {

        $('.WeOffers-SldrImg').slick({
            arrows: false,
            dots: false,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: 'ondemand',
            lazyLoaded: function (event, image) {
                console.log(image);
            },
            asNavFor: '.WeOffers-SldrText'
        });
        $('.WeOffers-SldrText').slick({
            dots: true,
            arrows: true,
            infinite: false,
            appendDots: $('.slide-m-dots'),
            prevArrow: $(".slide-m-prev"),
            nextArrow: $(".slide-m-next"),
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.WeOffers-SldrImg'
        });

        /** Slider */
        $('.centerDptoSldr').slick({
            dots: true,
            arrows: true,
            infinite: false,
            appendDots: $('.slide-m-dots'),
            prevArrow: $(".slide-m-prev"),
            nextArrow: $(".slide-m-next"),
            slidesToShow: 1,
            slidesToScroll: 1,
        });

    };
})();
