window.KnowYourHonda = new (function () {

    const Swal = require('sweetalert2');

    var that = this;
    var widthSize = $(window).width();
    var isIE11 = !!navigator.userAgent.match(/Trident.*rv\:11\./);
    var slideParts = [
        'exterior',
        'interior',
        'brakes',
        'tires',
        'under-the-car',
        'under-the-hood',
    ];


    /**
     * Init
     */
    this.init = function () {
        that.initPartsCarrousel();
        $(window).resize(that.notMobile).trigger('resize');

        $('.handleMdlGal').on('click', that.showGallery);
        $('.handleMdlVideo').on('click', that.showVideoGallery);

        $('.KnowModal-close').on('click', function () {
            $('.know-your-honda').removeClass('openMdlGal');
            $('.know-your-honda').removeClass('openMdlVideo');
            //$(".KnowModal-video").slick('unslick');
            var sldrOpt = {
                settings: 'unslick',
            }

            $('.KnowModal-sldr').not('.slick-initialized').slick(sldrOpt);
            $('.KnowModal-sldr')[0].slick.unslick();
            $('.KnowModal-video').not('.slick-initialized').slick(sldrOpt);
            $('.KnowModal-video')[0].slick.unslick();
        });

        //
        $('.wrap-categories .header').on('click', that.showHideSubcategories);

        //
        $('.wrap-categories .collapse .intern li').on('click', that.showPartInfo);
        $('.wrap-categories .collapse .intern li:first').trigger('click');

        $('.parts-carrousel .item .part').on('click', that.changeSubcategories);
        $('.parts-carrousel .item .part:first').trigger('click');

        //trigger to section (url parameter)
        if(triggerToSection != '' && triggerToPart != '') {
            $('.parts-carrousel .item.'+ triggerToSection +' a').trigger('click');
            $('.wrap-categories .collapse.'+ triggerToSection +' .intern li.'+ triggerToPart).trigger('click');
        }

    };

    /**
     * Parts Carrousel
     */
    this.initPartsCarrousel = function () {

        sldrOpt = {
            dots: true,
            arrows: true,
            prevArrow: $('.prev'),
            nextArrow: $('.next'),
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            autoplay: false,
            centerMode: true,
            appendDots: $(".navDots"),
            responsive: [
                {
                    breakpoint: 999999,
                    settings: 'unslick',
                }, {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        centerPadding: '0px',
                    }
                }
            ]
        }

        if (that.isIE11) {
            $('.parts-carrousel').slick(sldrOpt);
            $('.parts-carrousel').not('.slick-initialized').slick(sldrOpt);
        } else {
            $('.parts-carrousel').not('.slick-initialized').slick(sldrOpt);
        }

        $(window).on('resize', function () {
            if (!$('.parts-carrousel').hasClass('slick-initialized')) {
                return $('.parts-carrousel').slick(sldrOpt);
            }

        });

        $('.parts-carrousel').on('afterChange', function (event, slick, currentSlide, nextSlide) {
            var showParts = $('.parts-carrousel .item.slide-' + currentSlide + ' a').data('part');
            $('.wrap-categories .collapse').removeClass('active');
            $('.wrap-categories .collapse.' + showParts).addClass('active');

            $('.wrap-categories .collapse.' + showParts + ' .intern li:first').trigger('click');
        });

    };

    /**
     * Check for mobile
     */
    this.notMobile = function () {
        let windowsCheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (windowsCheck > 767) {
            $('.parts-carrousel').addClass('partDsk');
        } else {
            $('.parts-carrousel').removeClass('partDsk');
        }

    }
    /**
     * Show / Hide Subcategories
     */
    this.showHideSubcategories = function () {
        $(this).toggleClass('active');
        $(this).next().toggleClass('active');
    };

    /**
     * show part info
     */
    this.showPartInfo = function () {
        var title = $(this).data('title');
        var description = $(this).data('description');
        var finalTitle = title.replace(':', '/');
        $('.wrap-categories ul li.collapse ul li').removeClass('tint');
        $(this).addClass('tint');

        $('.wrap-info').find('.title').html(finalTitle);
        $('.wrap-info').find('.description').html('');
        $('.wrap-info').find('.description').append(description);

        $(this).each(function() {
            $.each(this.attributes, function() {
                // this.attributes is not a plain object, but an array
                // of attribute nodes, which contain both the name and value
                if(this.specified) {
                    //console.log(this.name, this.value);
                }
            });
        });

        var textData = $(this).data('title');

        //parts request button
        $('.wrap-info').find('.wrap-videophoto .videophoto .videophoto-link.handleMdlReq').attr('href', routePartsRequest + '/' + textData );

        that.changeGalleryThumb($(this));
    };

    /**
     * galleries
     */
    this.showGallery = function () {
        var slideWrapper = $(".KnowModal-sldr"),
            lazyImages = slideWrapper.find('.slide-image'),
            imagesDescription = slideWrapper.find('.image-description'),
            lazyCounter = 0;

        $('body.know-your-honda').addClass('openMdlGal');

        if (!that.isIE11) {
            slideWrapper.on("lazyLoaded", function (event, slick, image, imageSource) {
                lazyCounter++;
                if (lazyCounter === lazyImages.length) {
                    lazyImages.addClass('show');
                }
            });
        }

        //start the slider
        slideWrapper.slick({
            autoplaySpeed: 4000,
            //lazyLoad: "progressive",
            speed: 600,
            arrows: true,
            dots: false,
            infinite: false,
            nextArrow: '<button type="button" class="slick-next" aria-label=""><img src="/images/arrow-left-w.svg" class="img-fluid"></span></button>',
            prevArrow: '<button type="button" class="slick-prev" aria-label=""><img src="/images/arrow-right-w.svg" class="img-fluid"></span></button>',
            cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)"
        });
    }

    /**
     * Video gallery
     */
    this.showVideoGallery = function () {
        var slideWrapper = $(".KnowModal-video"),
            iframes = slideWrapper.find('.embed-player'),
            lazyImages = slideWrapper.find('.slide-image'),
            lazyCounter = 0;

        $('body.know-your-honda').addClass('openMdlVideo');

        // POST commands to YouTube or Vimeo API
        function postMessageToPlayer(player, command) {
            if (player == null || command == null) return;
            player.contentWindow.postMessage(JSON.stringify(command), "*");
        }

        // When the slide is changing
        function playPauseVideo(slick, control) {
            var currentSlide, slideType, startTime, player, video;

            currentSlide = slick.find(".slick-current .item");
            slideType = currentSlide.attr("class").split(" ")[1];
            player = currentSlide.find("iframe").get(0);
            startTime = currentSlide.data("video-start");

            if (slideType === "vimeo") {
                switch (control) {
                    case "play":
                        if ((startTime != null && startTime > 0) && !currentSlide.hasClass('started')) {
                            currentSlide.addClass('started');
                            postMessageToPlayer(player, {
                                "method": "setCurrentTime",
                                "value": startTime
                            });
                        }
                        postMessageToPlayer(player, {
                            "method": "play",
                            "value": 1
                        });
                        break;
                    case "pause":
                        postMessageToPlayer(player, {
                            "method": "pause",
                            "value": 1
                        });
                        break;
                }
            } else if (slideType === "youtube") {
                switch (control) {
                    case "play":
                        postMessageToPlayer(player, {
                            "event": "command",
                        });
                        postMessageToPlayer(player, {
                            "event": "command",
                            "func": "playVideo"
                        });
                        break;
                    case "pause":
                        postMessageToPlayer(player, {
                            "event": "command",
                            "func": "pauseVideo"
                        });
                        break;
                }
            } else if (slideType === "video") {
                video = currentSlide.children("video").get(0);
                if (video != null) {
                    if (control === "play") {
                        video.play();
                    } else {
                        video.pause();
                    }
                }
            }
        }

        // Resize player
        function resizePlayer(iframes, ratio) {
            if (!iframes[0]) return;
            var win = $(".KnowModal-video"),
                width = win.width(),
                playerWidth,
                height = win.height(),
                playerHeight,
                ratio = ratio || 16 / 9;

            iframes.each(function () {
                var current = $(this);
                if (width / ratio < height) {
                    playerWidth = Math.ceil(height * ratio);
                    current.width(playerWidth).height(height).css({
                        left: (width - playerWidth) / 2,
                        top: 0
                    });
                } else {
                    playerHeight = Math.ceil(width / ratio);
                    current.width(width).height(playerHeight).css({
                        left: 0,
                        top: (height - playerHeight) / 2
                    });
                }
            });
        }

        // DOM Ready
        $(function () {
            // Initialize
            slideWrapper.on("init", function (slick) {
                slick = $(slick.currentTarget);
                setTimeout(function () {
                    playPauseVideo(slick, "play");
                }, 1000);
                //resizePlayer(iframes, 16/9);
            });
            slideWrapper.on("beforeChange", function (event, slick) {
                slick = $(slick.$slider);
                playPauseVideo(slick, "pause");
            });
            slideWrapper.on("afterChange", function (event, slick) {
                slick = $(slick.$slider);
                playPauseVideo(slick, "play");
            });
            slideWrapper.on("lazyLoaded", function (event, slick, image, imageSource) {
                lazyCounter++;
                if (lazyCounter === lazyImages.length) {
                    lazyImages.addClass('show');
                    // slideWrapper.slick("slickPlay");
                }
            });
            //start the slider
            slideWrapper.slick({
                // fade:true,
                autoplaySpeed: 4000,
                //lazyLoad: "progressive",
                speed: 600,
                arrows: true,
                dots: false,
                infinite: false,
                nextArrow: '<button type="button" class="slick-next" aria-label=""><img src="/images/arrow-left-w.svg" class="img-fluid"></span></button>',
                prevArrow: '<button type="button" class="slick-prev" aria-label=""><img src="/images/arrow-right-w.svg" class="img-fluid"></span></button>',
                cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)"
            });
        });

        // Resize event
        $(window).on("resize.slickVideoPlayer", function () {
            resizePlayer(iframes, 16 / 9);
        });
    }

    /**
     * Change Subcategories
     */
    this.changeSubcategories = function () {
        if (widthSize > 1023) {
            var section = $(this).data('part');
            var imgToChange = $('.parts-carrousel .item .part.' + section).find('img');
            $('.parts-carrousel .item .part').removeClass('current');
            $(this).addClass('current');

            $.each($('.parts-carrousel .item .part'), function (index, item) {
                var ico = $(this).data('part');
                $(this).find('img').attr('src', publicAsset + '/ico-' + ico + '.svg');
            });
            $(imgToChange).attr('src', publicAsset + '/ico-' + section + '-gray.svg');

            $('.wrap-categories .collapse').removeClass('active');
            $('.wrap-categories .collapse.' + section).addClass('active');
            $('.wrap-categories .collapse.' + section + ' .intern li:first').trigger('click');
        }
    };

    /**
     * Change gallery thumb
     */
    this.changeGalleryThumb = function (optionSelected) {

        var folder = $(optionSelected).data('folder');
        var subfolder = $(optionSelected).data('subfolder');
        var mediaFolder = 'images/know-your-honda/' + folder + '/' + subfolder + '/' + siteLang;
        var fullFolder = publicAsset + '/know-your-honda/' + folder + '/' + subfolder + '/' + siteLang;
        var thumbImg = publicAsset + '/know-your-honda/' + folder + '/' + subfolder + '/thumb.jpg';

        $('.wrap-videophoto figure img').addClass('d-none');
        $('.wrap-videophoto').addClass('d-none');


        if (that.theFileExist(thumbImg)) {
            $('.wrap-videophoto').removeClass('d-none');
            $('.wrap-videophoto figure img').removeClass('d-none');
            $('.wrap-videophoto figure img').attr('src', thumbImg);
        }

        that.getPartImagesAndVideos(mediaFolder, fullFolder);
    };

    /**
     * Get media part
     */
    this.getPartImagesAndVideos = function (mediaFolder, fullFolder) {
        //define data for web service
        var settings = {
            url: getPartMediaRoute,
            method: "POST",
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'folder': mediaFolder,
            }
        }

        //ajax call
        $.ajax(settings).done(function (json) {

            that.processMedia(json.response, fullFolder);

        }).fail(function (xhr, status, error) {

            console.log(error);
            //ajax call fail
            /*
            Swal.fire(
                'An error ocurred',
                error,
                'error'
            );
             */

        });
    };

    /**
     * Process Media
     */
    this.processMedia = function (media, fullFolder) {

        //Show / Hide actions buttons
        $('.handleMdlGal').removeClass('d-none');
        $('.handleMdlVideo').removeClass('d-none');

        //Remove / Add images to carrousel
        var sldrOpt = {
            settings: 'unslick',
        }

        //$('.KnowModal-sldr').not('.slick-initialized').slick(sldrOpt);
        //$('.KnowModal-video').not('.slick-initialized').slick(sldrOpt);

        if (media.images.length == 0) {
            //hide images btn
            $('.handleMdlGal').addClass('d-none');
        } else {
            //empty slider
            $('.KnowModal-sldr').html('');
            //add media
            var htmlToAdd = '';

            $.each(media.images, function (index, file) {
                var imgDesc = file.split('.');
                var finalTitle = imgDesc[0].replace(':', '/');

                htmlToAdd = '<div class="item image">';
                htmlToAdd += '<div class="slide-image slide-media">';
                htmlToAdd += '<div class="image-description">' + finalTitle + '</div>';
                htmlToAdd += '<figure>';
                if(that.isIE11) {
                    htmlToAdd += '<img src="' + fullFolder + '/' + file + '" class="img-fluid image-entity">';
                } else {
                    htmlToAdd += '<img data-lazy="'+ fullFolder + '/' + file + '" class="img-fluid image-entity">';
                }
                htmlToAdd += '</figure>';
                htmlToAdd += '</div>';
                htmlToAdd += '</div>';

                $('.KnowModal-sldr').append(htmlToAdd);
            });

        }

        if (media.videos.length == 0) {
            //hide videos btn
            $('.handleMdlVideo').addClass('d-none');
            $('.KnowModal-video').html('');
        } else {
            //empty slider
            $('.KnowModal-video').html('');
            //add media
            var htmlToAdd = '';

            $.each(media.videos, function (index, file) {

                var fileName = file.split('.');
                var fileDescription = fileName[0].split('*');

                htmlToAdd += '<div class="item youtube">';

                htmlToAdd += '<div class="video-description">'+ fileDescription[0] +'</div>';
                htmlToAdd += '<iframe class="embed-player slide-media" width="100%" height="100%" src="https://www.youtube.com/embed/' + fileDescription[1] + '?enablejsapi=1&controls=0&fs=0&iv_load_policy=3&rel=0&showinfo=0&loop=1&start=1" frameborder="0" allowfullscreen allow="autoplay"></iframe>';

                htmlToAdd += '</div>';

            });

            $('.KnowModal-video').append(htmlToAdd);

        }

    };

    /**
     * Check if file exist
     * @param {string} url
     */
    this.theFileExist = function (url) {
        var http = new XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();
        return http.status != 404;
    };

})();

$(window).resize(function () {

    KnowYourHonda.widthSize = $(window).width();

    if (KnowYourHonda.widthSize < 1023) {
        KnowYourHonda.initPartsCarrousel();
    } else {
        try {
            KnowYourHonda.unSlickCarrousel();
        } catch (e) {

        }
    }

});
