window.PartRequest = new (function () {

    var that = this;

    /**
     * Init
     */
    this.init = function () {

        $('.add-part').on('click', that.addPartDescriptionField);
        $(document).on('click', '.delete-field', that.deletePartDescriptionField);

    };

    /**
     * add part description field
     */
    this.addPartDescriptionField = function () {

        var theField = '<div class="form-group mt-2 part-number-field position-relative">';
        theField += $('.part-number-field').html();
        theField += '<span class="delete-field"><img src="/images/icon-close-red.svg"/></span>';
        theField += '</div>';

        $(this).parent().parent().find('.part-number-field').last().after(theField);

    };

    /**
     * add part description field
     */
    this.deletePartDescriptionField = function () {

        $(this).parent().remove();

    };

})();
