/* ===================================
 * RECALL INFORMATION
 ===================================== */

window.Recall = new (function () {

    var that = this;

    var openResults = $('.check-vin'),
        loader = $('.result-loader'),
        loaderGif = $('.result-loader-gif'),
        lateral = $('.results-box');

    var showModal = $('.check-vin'),
        $body = $('.recall-box').outerHeight(),
        overlay = $('<div/>', {
            'class': "ui-overlay",
        });

    this.init = function () {

        /**
         * Modal New
         */

        $('.closeVin').on('click', function () {
            $('.ui-overlay').removeClass('visible').remove();
            $('.wrap-result').addClass('d-none');
        });


        /*
         * Scroll down
         */
        $('.Recall-vin_find a').on('click', function(e){
            e.preventDefault();
            var vinTarget = $(this).attr('href');
            console.log($(vinTarget));
            $('html, body').stop().animate({
                scrollTop: $(vinTarget).offset().top - 85
            }, 1500 );
        });


        //Validate VIN code
        $('#check-vin').on('click', that.validateVIN);

        $('#vin').on('keypress', function (e) {
            if (e.which == 13) {
                that.validateVIN();
            }
        });

        $('#search-vin').on('submit', function (e) {
            e.preventDefault();
        });

        /*
         * Cierra video
         */
        $('.video-close').on('click', function () {
            $('video')[0].pause();
            $('.video-box').removeClass('active');
            $('.OverlayModal').removeClass('active');
        });

        /*
         * Form Contact Recall
         */

        $('#sendRecallInfoBtn').on('click', that.validateRecallForm);

    };

    /*
     * Validate VIN
     */

    this.validateVIN = function () {
        var vin = $('#vin').val();
        var vinBtn = $(this);
        var errorContent = $('#error-catch');
        errorContent.html('');
        $('.result-loader').removeClass('d-none');

        vinBtn.prop("disabled", true);

        loader.removeClass('d-none');
        loaderGif.removeClass('d-none');

        if ($.trim(vin) == '') {
            errorContent.html(messageError);
            loader.addClass('d-none');
            loaderGif.addClass('d-none');
            vinBtn.prop("disabled", false);
            return false;
        }

        var settings = {
            "url": siteBase + "get-recall/" + vin,
            "method": "GET",
        }

        $.ajax(settings).done(function (json) {

            if (json == 'Error') {
                errorContent.html(messageError);
                loader.addClass('d-none');
                loaderGif.addClass('d-none');
                vinBtn.prop("disabled", false);
                return false;
            }

            var obj = $.parseJSON(json);
            var header = obj.header;

            if (typeof header != "undefined") {

                if (header.status) {
                    that.openResults(obj);
                    vinBtn.prop("disabled", false);
                }
                if (header.errorType) {
                    errorContent.html(messageError);
                    loader.addClass('d-none');
                    loaderGif.addClass('d-none');
                    vinBtn.prop("disabled", false);
                }

            } else {
                errorContent.html(messageError);
                loader.addClass('d-none');
                loaderGif.addClass('d-none');
                vinBtn.prop("disabled", false);
            }

        }).fail(function () {
            errorContent.html(messageError);
            loader.addClass('d-none');
            loaderGif.addClass('d-none');
            vinBtn.prop("disabled", false);
        });

    };



    /*
     * Abrir lateral de resultados
     */

    this.openResults = function (obj) {

        //openModal.addClass('ShowModal');
        $('body').append(overlay);
        $('.ui-overlay').addClass('visible');
        $('.wrap-result').removeClass('d-none');


        var mainContent = $('.results-list');
        var headContent = $('.Recall-results-vin');
        var body = obj.body.lacCampaignsDTO;
        var recalls = obj.body.lacCampaignsDTO.lacCampaignInfoDTOs;
        var recallsContent = '';

        //Format Last Update
        var dateAr = body.last_updated.split('-');
        var newM = parseInt(dateAr[1]);
        var newD = dateAr[2];
        var newY = dateAr[0];
        if (lang == 'es') {
            newM = monthEs[newM];
        } else {
            newM = monthEn[newM];
        }

        var countOpenCampaigns = 0;

        if (!$.isEmptyObject(recalls)) {
             var openCampaign = false;

            campaign_array = [];

            //Recall List
            $.each(recalls, function (key, val) {

                //Only Open Campaigns
                if(val.camp_compl_status == "N") {

                    openCampaign = true;

                    var dateAr = val.camp_start_dt.split('/');
                    var newM = parseInt(dateAr[0]);
                    var newD = dateAr[1];
                    var newY = dateAr[2];

                    if (lang == 'es') {
                        newM = monthEs[newM];
                    } else {
                        newM = monthEn[newM];
                    }

                    //Campaign Status
                    var bodyStatus = body_status_lang1;
                    if (val.camp_type_cd != "C") {
                        bodyStatus = body_status_lang2;
                    }

                    countOpenCampaigns = countOpenCampaigns + 1;

                    recallsContent += '<div class="VinCar-safety VinCar-elem ShadowBox">';
                    recallsContent += '<span class="VinResult-titles">';
                    recallsContent += val.camp_desc;
                    recallsContent += '</span>';
                    recallsContent += '<div class="VinCar-safety_list">';
                    recallsContent += '<span>' + campaingCode + ': <span class="VinCar-safety_in">' + val.camp_no + '</span></span>';
                    recallsContent += '<span>' + campaingDescription + ': <span class="VinCar-safety_in">' + val.camp_desc + '</span></span>';
                    recallsContent += '<span>' + campaingDate + ': <span class="VinCar-safety_in">' + newD + '-' + newM + '-' + newY + '</span></span>';
                    recallsContent += '<span>' + campaingStatus + ': <span class="VinCar-safety_warning">' + bodyStatus + '</span></span>';
                    recallsContent += '</div>';
                    recallsContent += '</div>';

                    campaign_array.push(val.camp_no + ' :: ' + val.camp_desc);

                }//end if only open campaigns

            });//end $each

            if(openCampaign) {
                $('.Vin-affected').removeClass('d-none');
            } else {
                recallsContent += '<div class="VinCar-safety VinCar-elem ShadowBox">';
                recallsContent += '<span class="VinResult-titles">';
                recallsContent += noRecalls;
                recallsContent += '</span>';
                recallsContent += '</div>';

                $('.Vin-affected').addClass('d-none');
                $('.steps-whats-next').html(StepsTitle);
            }

        } else {
            recallsContent += '<div class="VinCar-safety VinCar-elem ShadowBox">';
            recallsContent += '<span class="VinResult-titles">';
            recallsContent += noRecalls;
            recallsContent += '</span>';
            recallsContent += '</div>';

            $('.Vin-affected').addClass('d-none');
            $('.steps-whats-next').html(StepsTitle);
        }

        //Head
        headContent.find('.VinCode').html('VIN: ' + body.vin_no);
        headContent.find('.year-result').html(body.year);
        headContent.find('.make-result').html(body.make);
        headContent.find('.model-result').html(body.model);
        headContent.find('.num_recalls-result').html(countOpenCampaigns);
        headContent.find('.last_update-result').html(newD + '-' + newM + '-' + newY);
        mainContent.find('.VinCar-safety').remove();
        vin_region = body.dest_cd;

        mainContent.append(recallsContent);


        setTimeout(function () {
            $("html, body").animate({scrollTop: 0}, 1000, function () {
                lateral.addClass('showResults');
                loader.addClass('d-none');
                loaderGif.addClass('d-none');
            });
        }, 1000);
    };


    /*
     * Valida Contact Recall Form
     */

    this.validateRecallForm = function() {

        //Se obtinen los campos del formulario
        var buttonSend = $('#sendRecallInfoBtn');
        $('#sendRecallInfoBtn').prop("disabled", true);
        var nameInput = $('#recallName');
        var addressInput = $('#recallAddress');
        var emailInput = $('#recallEmail');
        var phoneInput = $('#recallPhone');
        var messageInput = $('#recallMessage');
        var vinInput = $('#recallVin');
        var tokenInput = $('#_token');
        var campaignInput = $('#campaign');
        var countryInput = $('#country');
        var validator = true;

        nameInput.removeClass('error');
        addressInput.removeClass('error');
        emailInput.removeClass('error');
        phoneInput.removeClass('error');
        messageInput.removeClass('error');
        $('.form-message').removeClass('error');
        $('.form-message').html('');
        buttonSend.find('.send-info-loader').removeClass('d-none');

        //Validación de campos requeridos
        if( $.trim(nameInput.val()) == '' || ( $.trim(emailInput.val()) == '' && $.trim(phoneInput.val()) == '') ) {
            nameInput.addClass('error');
            emailInput.addClass('error');
            phoneInput.addClass('error');
            $('.form-message').addClass('error');
            $('.form-message').html(requiredFields);
            buttonSend.find('.send-info-loader').addClass('d-none');
            validator = false;
            $('#sendRecallInfoBtn').prop("disabled", false);
        }

        if( validator ) {
            var settings = {
                url: sendRecallContactRoute,
                type: 'POST',
                data: {
                    '_token': tokenInput.val(),
                    'name': nameInput.val(),
                    'email': emailInput.val(),
                    'phone': phoneInput.val(),
                    'address': addressInput.val(),
                    'message': messageInput.val(),
                    'campaign': campaignInput.val(),
                    'campaign_codes': campaign_array,
                    'country': countryInput.val(),
                    'vin': $('#vin').val(),
                    'region': vin_region,
                }
            }

            $('.form-message').html('');

            $.ajax(settings).done(function (json) {
                $('.form-message').addClass('ok');
                $('.form-message').html(messageSuccessForm);
                buttonSend.find('.send-info-loader').addClass('d-none');
                $('#sendRecallInfoBtn').prop("disabled", false);
            }).fail(function () {
                $('.form-message').addClass('error');
                $('.form-message').html(messageErrorForm);
                buttonSend.find('.send-info-loader').addClass('d-none');
                $('#sendRecallInfoBtn').prop("disabled", false);
            });
        }

        //ga('send','event', 'VIN registered','recall-send','click submit recall');
        try {
            gtag('event', 'VIN registered', {
                'event_category': 'recall-send',
                'event_label': 'click-submit-recall'
            });
        } catch (e) {
            console.log(e);
            console.log('Error send event GA4');
        }

        return;

    };

    /**
     * Validate contact recall form
     * @param data
     */
    this.sendRecallContact = function (data) {


    };

})();
