import {scrollTo} from './../../js/helpers';
const StoreLocator = function () {
    const $map = $('#map-canvas');
    const $win = $(window);
    const $header = $('header');
    const $dealerBox  = $('.DealerBox');

    const fixScroll = e => {
        const $footer = $('footer');
        const headerHeight = $header.height();
        const $stores = $('.Service-center_stores');
        const offsetTop = ($stores.length > 0) ? $stores.offset().top - headerHeight : 0;
        const offsetBottom = ($stores.length > 0) ? $footer.offset().top - $map.height() : 0;
        const $service_center_map = $('.Service-center_map');
        if($win.width() < 768) return;
        if( window.pageYOffset >  offsetTop && window.pageYOffset < offsetBottom ) {
            $('.fixmap').css({
                position:'fixed',
                top: headerHeight,
                bottom: 0,
                left: $service_center_map.offset().left,
                right: 0
            });
        } else if ( window.pageYOffset > $stores.outerHeight() + $dealerBox.outerHeight() - headerHeight*2 - $map.height() ) {
            $('.fixmap').css({
                position:'relative',
                top: $stores.outerHeight() - $map.height(),
                bottom: 0,
                left: 0,
                right: 0
            });
        } else{
            $('.fixmap').css({
                position:'relative',
                top: 'auto',
                bottom: 'auto',
                left: 'auto',
                right: 'auto'
            });
        }
    }
    const resize = (x) => {
        if($win.width() < 768) {
            //$map.height($win.height());
            $('.fixmap').css({
                position:'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,

            });
            return;
        }
        $('.Service-center_map').show();
        $map.height($win.height() - $header.height());
        fixScroll();
    };
    $win.on('resize', resize);
    $win.scroll(fixScroll);
    resize();
    $('.closeMap').removeClass('showMap');
    let markers = new Array();

    let mapOptions = {
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        center: new google.maps.LatLng(32.2217407, -110.9264832),
        panControl: !false,
        zoomControl: !false,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        overviewMapControl: false,
        rotateControl: true,
        scrollwheel: false,
    };

    let map = new google.maps.Map($map[0], mapOptions);
    const bounds = new google.maps.LatLngBounds();

    $('.Service-locator').each((i, divService) => {
        const position = new google.maps.LatLng(
            divService.dataset.lat,
            divService.dataset.lng
        );
        const marker = new google.maps.Marker({
            position,
            map
        });
        google.maps.event.addListener(marker, 'click', () => {
            const $divService = $(divService);
            $divService.click();
            scrollTo($divService, $header.height() * 3);
        });
        divService.honda_position = position;
        bounds.extend(position);
    });
    map.fitBounds(bounds);

    $('.Service-locator').on('click', function () {
        $('.Service-locator').removeClass('active');
        map.setCenter(this.honda_position);
        map.setZoom(17);
        $(this).addClass('active');
    });

    $('.BtnStd.BtnStd-primary').click(e => {
        $('html, body').css({
            overflow: 'hidden',
        });
        $('.Service-center_map').fadeIn("slow");
    });
    $('.closeMap').click(e=>{
        $('.Service-center_map').fadeOut('slow');
        $('html, body').css({
            overflow: 'auto',
        });
    });

};

window.StoreLocator = StoreLocator;
