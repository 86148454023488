window.OurCars = new (function () {

    var that = this;
    let toCar = $('.OurVehicles-tabs.build .MenuVehicles_types');
    var sectionCallBack;
    var setOpt = {
        infinite: false,
        dots: false,
        arrows: false,
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 99999,
                settings: {
                    slidesToShow: 3,
                    infinite: false,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    infinite: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                    infinite: false,
                }
            }
        ]
    }

    /**
     * Init
     */
    this.init = function () {

        //remove cars categories
        //that.removeCarsCategories();

        $('.OurVehicles-tabs').simpleTab({
            container: '.OurVehicles-bar',
            handler: '.OurVehicles-bar>a',
            content: '.MenuVehicles_main',
            attribute: 'rel',
            namespeace: 'oveh',
            effect: "class",
            caller: function () {
                toCar.filter('.slick-initialized').slick('refresh')
            }
        });

    };

    /**
     * Init slicks
     */
    this.initSlickCarMenu = function () {

        toCar.each(function (i) {
            $(this).attr('id', 'OurVehiclesCar' + i++);
        });

        let isSlider = toCar.each(function () {
            return $(this).attr('id');
        });

        toCar.each((index, elem) => {
            $(elem).slick(setOpt);
        });

    };

    /**
     * Remove Cars Categories
     * for mobiles
     */
    this.removeCarsCategories = function () {
        toCar.remove('.Vehicles-type');
    };

    function isMobileOurCars() {
        let windowsCheck = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        if (windowsCheck > 1025) {
            toCar.each((index, elem) => {
                if ($(elem).hasClass('slick-initialized')) {
                    $(elem).filter('.slick-initialized').slick('unslick');
                }
            });
            return;
        }

        //that.removeCarsCategories();
        if (typeof sectionHomeSlider !== "undefined") {

            if (windowsCheck < 700) {
                var setOpt = {
                    infinite: false,
                    dots: false,
                    arrows: false,
                    slidesToShow: 1,
                }
            } else {
                toCar.each((index, elem) => {
                    if ($(elem).hasClass('slick-initialized')) {
                        $(elem).filter('.slick-initialized').slick('unslick');
                    }
                });
                var setOpt = {
                    infinite: false,
                    dots: false,
                    arrows: false,
                    slidesToShow: 2,
                }
            }
        }

        if (!toCar.hasClass('slick-initialized')) {

            toCar.each((index, elem) => {
                $(elem).find('.Vehicles-type').remove();
                $(elem).slick(setOpt);
            });

            //remove cars categories

            return;
        }

    }

    $(window).resize(isMobileOurCars).trigger('resize');

})();
