window.Contact = new (function () {

    const Swal = require('sweetalert2');
    var that = this;
    var carselected = 'no-car';

    /**
     * Init
     */
    this.init = function () {

        $('#sendContactForm').on('click', that.sendContactForm);

        carselected = $( "#model option:selected" ).text();

        $('#model').on('change', function (e) {
            var car = this.options[e.target.selectedIndex].text;
            var fullCarName = car.split(" ");
            var firstName = '';
            var lastName = '';

            if(siteLang == 'en') {
                firstName = car.substr(car.indexOf(" ") + 1);
            } else {
                firstName = fullCarName.slice(0, -1).join(" ");
            }

            carselected = ($.trim(firstName) != '') ? $.trim(firstName) : car;

        });

    };

    /**
     * Send contact form
     */
    this.sendContactForm = function () {

        //ga('send','event', carselected, actionSendAdvisor, tagSendAdvisor);
        try {
            gtag('event', carselected, {
                'event_category': actionSendAdvisor,
                'event_label': tagSendAdvisor
            });
        } catch (e) {
            console.log(e);
            console.log('Error send event GA4');
        }

        //console.log(carselected, actionSendAdvisor, tagSendAdvisor);

        //$('#contactForm').submit();

    };

    /**
     * Open new tab
     */
    this.openNewTab = function () {
        var href = $('#newTab').attr('href');
        var win = window.open(href, '_blank');
        if (win) {
            //Browser has allowed it to be opened
            win.focus();
        } else {
            //Browser has blocked it
            Swal.fire('Tab bloqueada', 'El navegador está bloqueando la nueva ventana. Por favor de click en el botón para ver la información');
        }
    };


})();
