window.ShoppingTools = new (function () {

    const Swal = require('sweetalert2');

    var that = this;

    /**
     * Init
     */
    this.init = function () {

        //financing
        $('.FinancingOptions').simpleTab({
            container: '.Financing-names',
            handler: '.Financing-names>a',
            content: '.Financing-data',
            attribute: 'rel',
            namespeace: 'fin',
        });
        isMobile();

        //offers
        //$('.Offers-item a').on('click', that.openAndPopulateModal);
        //$('.ModalClose').on('click', that.closeModal);

        //change image model
        $('#select-model').on('change', that.changeModelImage);

        if (typeof carModel != 'undefined' && carModel != '') {
            $('#select-model').trigger('change');
        }

        if (typeof section != 'undefined' && section != '') {
            that.scrollToElement(section);
        }

        $('#sendOfferRequest').on('click', that.submitOfferRequest);

        console.log('offer initiated');
    };

    /**
     * Change model image
     */
    this.changeModelImage = function () {
        var imageURL = $(this).find(':selected').attr('data-img');

        if(imageURL == '') {
            imageURL = defaultImg;
        }

        $('#imgModel').attr('src', imageURL);

        that.getTrims($(this).val());

    };

    /**
     * Get trims
     * @param trimID
     */
    this.getTrims = function (trimID) {

        $.ajax({
            type: 'POST',
            url: getTrimsRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'car_id': trimID,
            },
            success: function (response) {
                that.buildTrimsSelect(response);
            }
        });

    };

    /**
     * Build trims select
     * @param trims
     */
    this.buildTrimsSelect = function (trims) {

        $('#select-trim').removeClass('d-none');
        $('#select-trim').prev().removeClass('d-none');

        if(trims == '') {
            $('#select-trim').addClass('d-none');
            $('#select-trim').prev().addClass('d-none');
        }

        $('#select-trim').empty();

        $.each(trims, function (index, value) {
            $('#select-trim').append('<option value="' + value.price_compose + ' ' + value.name + '">' + value.price_compose + ' ' + value.name + '</option>');
        });

    };

    function isMobile() {

        var mql = window.matchMedia("(max-width: 767px)")

        mediaqueryresponse(mql)

        mql.addListener(mediaqueryresponse)

        function mediaqueryresponse(mql) {
            if (mql.matches) {
                $('.Financing-data').hide();
                $('.Financing-handler').click(function () {

                    var checkElement = $(this).next();

                    $('.Financing-handler').removeClass('current');
                    $(this).addClass('current');

                    if ((checkElement.is('.Financing-data')) && (checkElement.is(':visible'))) {
                        $(this).removeClass('current');
                        checkElement.slideUp();
                    }
                    if ((checkElement.is('.Financing-data')) && (!checkElement.is(':visible'))) {
                        $('.Financing-data:visible').slideUp();
                        checkElement.slideDown();
                    }

                    if (!checkElement.is('.Financing-data')) {
                        return false;
                    }
                });
                return;
            }
        }
    }

    //offers :: Modal
    this.openAndPopulateModal = function (e) {
        $('.ModalStd').addClass('openModal');
        $('#content').addClass('noScroll');
        return false;
    };

    this.closeModal = function () {
        $('.ModalStd').removeClass('openModal');
        $('#content').removeClass('noScroll');
    };

    /**
     * Scroll to element
     */
    this.scrollToElement = function (section) {

        $([document.documentElement, document.body]).animate({
            scrollTop: $("#" + section).offset().top - 35
        }, 1000);

    };

    /**
     * Init for Test Drive
     */
    this.initTestDrive = function () {
        //listener change dealership
        $('#location').on('change', that.changeMinAndMaxTime);
        //trigger for first dealership
        $('#location').trigger('change');
    };

    /**
     * Change min max time for schedule test drive
     */
    this.changeMinAndMaxTime = function () {
        //get schedule
        var dealerSelected = $(this).find('option:selected').data('schedule');

        //split for get min an max
        dealerSelected = dealerSelected.split(',');

        //
        if(dealerSelected.length > 1) {
            //put the new values
            $('#time').val(dealerSelected[0]);
            $('#time').attr('min', dealerSelected[0]);
            $('#time').attr('max', dealerSelected[1]);
        }
    };

    /**
     * Scroll to form if error exist
     * @constructor
     */
    this.ServiceCenterScrollForm = function () {
        $([document.documentElement, document.body]).animate({
            scrollTop: $("#service-center-form").offset().top - 35
        }, 1000);
    };

    /**
     * Submit offer request
     */
    this.submitOfferRequest = function () {

        //ga('send','event', currentOfferSelected,'Request Offer','click submit offer');
        try {
            gtag('event', currentOfferSelected, {
                'event_category': 'Request Offer',
                'event_label': 'click-submit-offer'
            });
        } catch (e) {
            console.log(e);
            console.log('Error send event GA4');
        }

        $('#formOfferRequest').submit();
    };

    /**
     * Get offer name
     */
    this.offerDetail = function (offerName, offerCategory) {
        currentOfferSelected = offerName;
        $('.ModalData .ModalThumb').removeClass('d-none');
        $('.ModalCoupon').addClass('d-none');
        $('.ModalForm').removeClass('d-none');

        if(offerCategorySelected == offerCategory) {
            $('.ModalData .ModalThumb').addClass('d-none');
            $('.ModalCoupon').removeClass('d-none');
            $('.ModalForm').addClass('d-none');
        }
    };

})();
